import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Smarkio: Layout changed.`}</li>
      <li parentName="ul">{`Panels: Added external Campaigns IDs as rules;`}</li>
      <li parentName="ul">{`Apps: Added the groups option to audios;`}</li>
      <li parentName="ul">{`Voice: Audio-Only Interaction Mode combobox added to Skill and Team settings;`}</li>
      <li parentName="ul">{`Bug Fix: Add missing "Teams Settings" label to audios app associations tab;`}</li>
      <li parentName="ul">{`Bug Fix: Exports with Terminated status show incorrect actions;`}</li>
      <li parentName="ul">{`Bug Fix: Export app - right scroll appears and disappears in cycle.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      